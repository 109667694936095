.h3 {
  font-size: 22px;
  font-weight: 700 !important;
  margin: 1rem 0;
}

.p {
  line-height: 32px;
}

.strong {
  font-weight: bold !important;
}
