@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

figure > img {
  width: 100%;
}

figcaption {
  display: none !important;
}

figure + h3,
p > a,
#single-blog > h3:last-child {
  color: #01a2bb;
}
